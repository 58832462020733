<template>
	<div id="body">
		<div class="fatherBox">
			<div class="headerBox">
				<p>健康档案 </p>
                <div><el-button type="primary" @click="doExport">添加</el-button> <el-button type="primary" @click="doExport">导出数据</el-button></div>
				
			</div>
			<div class="query">
				<div class="queryLeft">
				</div>
				<div class="queryRight" style="display: flex;">
					<div class="dataBox3">
						<div class="num">{{gradeLists.class_height_avg}}cm</div>
						<div class="text">班级平均身高</div>
					</div>
					<div class="dataBox4">
						<div class="num">{{gradeLists.class_weight_avg}}kg</div>
						<div class="text">班级平均体重</div>
					</div>
					<div class="dataBox2">
						<div class="num">{{gradeLists.class_big}}%</div>
						<div class="text">班级肥胖率</div>
					</div>
				</div>
			</div>

			<el-table :data="gradeLists.list" v-loading="loading">
				<el-table-column label="学号" prop="stu_id" width="auto" align="center"></el-table-column>
				<el-table-column label="性别" width="auto" align="center"><template slot-scope="scope">
						<span>{{scope.row.sex==1?'男':'女'}}</span>
					</template></el-table-column>
				<el-table-column label="更新日期" prop="create_date" width="auto" align="center"></el-table-column>
				<el-table-column label="年龄" prop="age" width="auto" align="center"></el-table-column>
				<el-table-column label="身高(cm)" prop="stu_height" width="auto" align="center"></el-table-column>
				<el-table-column label="距上次身高变化(cm)" prop="stu_height_change" width="auto" align="center"></el-table-column>
				<el-table-column label="体重(kg)" prop="stu_weight" width="auto" align="center"></el-table-column>
				<el-table-column label="距上次体重变化(kg)" prop="stu_weight_change" width="auto" align="center"></el-table-column>
                <el-table-column label="BMI" prop="bmi" width="auto" align="center"></el-table-column>
                <el-table-column label="评估BMI" prop="assess" width="auto" align="center"></el-table-column>
                <el-table-column label="操作" fixed="right" width="auto" align="center">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="doView(scope.row)" plain>详情
						</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div v-show="CountNum > 0">
				<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
					layout="total,prev, pager, next, jumper" :current-page="currpage" :total="CountNum"
					style="margin-top:20px">
				</el-pagination>
			</div>
		</div>

	</div>
</template>

<script>
	import Vue from 'vue'
	import {
		gradeListDetail
	} from '@/api/SchoolApi.js'
	import {
        handStu,
		gradeClass
	} from '@/api/TeacherApi.js'

	import {
		mapGetters
	} from 'vuex'
	export default {
		computed: {
			...mapGetters(['Status'])
		},
		data() {
			return {
				class_id: '',
				stateOptions: [{
						label: '正常',
						id: 1
					},
					{
						label: '异常',
						id: 2
					},
					{
						label: '发烧',
						id: 3
					},
				],
				check_date: '', //检测日期
				// class_id: this.$route.params.class_id,
				ClassInfoss: {},
				ClassInfos: [],
				export: '',
				loading: false, // 加载框
				pagesize: 10, // 每页显示条目个数
				currpage: 1, // 初始页
				CountNum: 0, // 总条数
				gradeid: '', // 年级查询id
				gradeLists: [], // 年级数组
				classid: '', // 班级查询id
				staus: '', //状态
				classList: [], // 班级数组
				dialogVisible: false,
				dialogVisibles: false,
				daterange: [],
				form: {
					day: '',
					gid: '', // 年级查询id
					cid: '', // 班级查询id
					bstatus: 1
				},
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
				},

				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							picker.$emit('pick', new Date());
						}
					}, {
						text: '昨天',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', date);
						}
					}, {
						text: '一周前',
						onClick(picker) {
							const date = new Date();
							date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', date);
						}
					}]
				},
				value1: '',
				value2: '',

			}
		},
		mounted() {
			// this.ClassList(1) // 获取数据列表
			this.gradeListvue() // 获取年级信息
			// this.DataList() // 统计数据
		},
		methods: {
			reset() { // 重置按钮
				this.classList = []
				this.gradeid = ''
				this.classid = ''
				this.staus = ''
				this.currpage = 1
				this.ClassList(this.currpage)
			},
			ClassList(currpage) { // 班级数据管理列表
				this.loading = true
				// console.log(this.class_id)
				const userInfo = JSON.parse(localStorage.getItem('userInfo'))
				this.class_id = userInfo.ClassID
				const data = {
					page: currpage, // 当前页数
					limit: this.pagesize, //	每页条数
					class_id: this.class_id
				}
				gradeListDetail(data).then(res => {
					this.ClassInfoss = res.data.inAllInfo
					this.ClassInfos = res.data.list
					this.CountNum = parseInt(res.data.countNum) // 总条数
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},

			// 查看详情
			doView(item) {
				this.$router.push({ // 页面跳转
					name: 'testDetailss', // 往这个页面跳转
					params: { // 跳转传递的参数
						student_id: item.student_id,
						student_name: item.student_name
					}
				})
			},

			handleCurrentChange(val) {
				if (this.gradeid == '') {
					this.ClassList(val)
					this.currpage = val
				} else {
					this.classListDataSearch(val) // 当有姓名条件时 分页操作
				}
			},
			classListDataSearchfirst(val) { // 只有当点击查询时候走这个方法

				const data = {
					page: val,
					limit: this.pagesize,
					class_id: this.classid,
					grade_id: this.gradeid,
					staus: this.staus,
					check_date: this.check_date

				}
				gradeListDetail(data).then(res => {
					this.currpage = 1
					this.ClassInfos = res.data.list
					this.CountNum = parseInt(res.data.countNum)
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},

			classListDataSearch(val) { // 当第一次 点了查询条件后 分页走这个方法
				this.currpage = val
				const data = {
					page: val,
					limit: this.pagesize,
					class_id: this.classid,
					grade_id: this.gradeid,
					staus: this.staus,
					check_date: this.check_date

				}

				gradeListDetail(data).then(res => {
					this.ClassInfos = res.data.list
					this.CountNum = parseInt(res.data.CountNum)
					this.loading = false
				}).catch(err => {
					console.log(err)
				})
			},
			doExport() { // 导出
				const userInfo = JSON.parse(localStorage.getItem('userInfo'))
				this.class_id = userInfo.ClassID
				const data = {
					page: this.currpage,
					limit: this.pagesize, //	每页条数
					class_id: this.class_id,

					export: 1
				}
				gradeListDetail(data).then(res => {


					this.Export = res.data.export_url
					console.log(res.data.export_url)
					window.open(this.Export)

				}).catch(err => {
					console.log(err)
				})
			},



			gradeListvue() { // 年级列表
				handStu().then(res => {
					this.gradeLists = res.data
					console.log(res.data.list)
					console.log(this.gradeLists)
				}).catch(err => {
					console.log(err)
				})
			},
			classSelect() { // 班级列表
				this.classid = ''
				this.classList = []

				const data = {
					gid: this.gradeid
				}

				if (this.gradeid != "") {
					gradeClass(data).then(res => {
						this.classList = res.data;
					}).catch(err => {
						console.log(err)
					})
				}

			},


		}

	}
</script>

<style lang="scss" scoped>
	#body {
		background: #f0f2f5;
	}

	.fatherBox {
		width: 98%;
		// height: 800px;
		display: flex;
		background: white;
		margin: 0 auto;
		flex-direction: column;
		justify-content: center;

		.headerBox {
			display: flex;
			height: 52px;
			width: 98%;
			align-items: center;
			justify-content: space-between;
			border-bottom: 2px solid #e9e9e9;

			p {
				font-size: 16px;
				font-family: 萍方-简;
				font-weight: normal;
				color: #000000;
				margin-left: 32px;
			}

			.btn {
				display: flex;
				align-items: center;
				width: 109px;
				height: 32px;
				opacity: 1;
				border-radius: 2px;
			}
		}

		.del {
			float: left,

		}

		.query {
			width: 98%;
			align-items: center;
			display: flex;
			justify-content: space-between;
			padding-top: 20px;
			padding-left: 32px;

			.queryLeft {
				width: 75%;
				display: flex;
				flex-wrap: wrap;

				div {
					width: 300px;
					line-height: 50px;
					text-align: left;

					.input,
					.select {
						width: 60%;
					}
				}

				.queryRight {}
			}
		}
	}

	.dataBox {
		padding: 5px 20px;
		font-size: 18px;
		cursor: pointer;

		.num {
			color: #333333;
			font-weight: 600;
			font-size: 16px;

		}

		.text {
			width: 60px;
			font-size: 16px;

		}
	}

	.dataBox1 {
		padding: 5px 20px;
		font-size: 18px;

		.num {
			font-weight: 600;
			font-size: 30px;
			color: #333333
		}

		.text {
			width: 80px;
			font-size: 1.25rem;

		}
	}

	.dataBox2 {
		padding: 5px 20px;
		font-size: 18px;

		.num {
			color: #027DB4;
			font-weight: 600;
			font-size: 16px;
		}

		.text {
			width: 100px;
			font-size: 16px;
			color: #027DB4;

		}
	}

	.dataBox3 {
		padding: 5px 20px;
		font-size: 18px;

		.num {
			color: #4B7902;
			font-weight: 600;
			font-size: 16px;
		}

		.text {
			width: 120px;
			font-size: 16px;
			color: #4B7902;

		}
	}

	.dataBox4 {
		padding: 5px 20px;
		font-size: 18px;

		.num {
			color: #D9001B;
			font-weight: 600;
			font-size: 16px;
		}

		.text {
			width: 100px;
			font-size: 16px;
			color: #D9001B;

		}
	}

	.dataBox5 {
		padding: 5px 20px;
		font-size: 18px;

		.num {
			color: #BFBF00;
			font-weight: 600;
			font-size: 30px;
		}

		.text {
			width: 120px;
			font-size: 1.25rem;
			color: #BFBF00;

		}
	}
</style>
